import sendRequest from "@/libs/sendRequest";

export const program = {
    namespaced: true,
    state: {
        data_order: null
    },
    getters: {
        getDetailsOrder: (state) =>(state.data_order),
    },
    mutations: {
        setDetailsOrder: (state, payload) => (state.data_order = payload),
    },
    actions: {
        viewDetailsOrder: async({commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/program/products/view','GET', null, null, {id: id}, custom_headers);

            if(response.success && response.data) {

                const product = response.data.list

                product.Imagen_1 = `https://qa-api.wiks-services.online/websales/program/products/resources?id=${product.ID}`
                product.Imagen_2 = `https://qa-api.wiks-services.online/websales/program/products/resources?id=${product.ID}`
                product.Imagen_3 = `https://aq-api.wiks-services.online/websales/program/products/resources?id=${product.ID}`

                commit('setProduct', response.data.list ?? {}) 
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('Se perdió la conexión con la API')
            }
        }
    }
}