<template>

    <section class="container-fluid text-start border rounded dmsans-semibold w-100">
        <div class="row">
            <div class="col-12 d-block d-sm-flex flex-between-center bg-light-gray">
                <div class="dmsans-semibold my-2">

                    Referencia: <strong class="dmsans-black">{{ reference }}</strong>

                    <span class="badge text-white p-2 ms-2" 
                        :class="`${(status == 'Requested') ? 'text-bg-danger' : 'text-bg-success'}`">
                        {{ 
                            status == 'Requested' ? 'Solicitado' : 
                            status == 'Approved' ? 'Aprobado' :'Otro' 
                        }}
                    </span>

                </div>

                <div class="w-sm-auto flex-end-center my-2">
                    <button @click="$redirect('view-deatils-redeem', { reference })" 
                        class="btn btn-light btn-sm rounded-pill border">
                        Ver Detalles
                    </button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center d-sm-block py-2">
                
                <div class="bg-light-gray flex-center float-sm-start rounded mb-2 mb-sm-0 p-2" 
                    style="height: 200px; width: 200px;">

                        <img :src="image"
                            alt="image to points product"
                            class="bg-white img-fluid rounded"
                            style="object-fit: scale-down;"
                            v-if="image"/>
                </div>


                <div class="d-flex flex-column justify-content-between h-sm-100 w-sm-auto px-2 py-2">

                    <div class="text-start dmsans-light m-0 mb-2 m-sm-2">
                        <span class="dmsans-semibold">Fecha:</span> {{ $informativeDate(creation, 'dw') }}<br>
                        <!-- <span class="dmsans-semibold">Total de productos:</span> {{ products.length }}<br> -->
                        <span class="dmsans-semibold">Puntos:</span> {{ (points !== null) ? points : 0 }}Pts<br>
                        <!-- <span class="dmsans-semibold">N° Orden:</span> {{ id_redeem }}<br> -->
                    </div>

                    <div class="flex-end-center bg-white m-0 m-sm-2 py-1">
                        <div class="d-flex d-sm-block d-md-flex">
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
    </section>
  </template>
  
  <script setup>
    // Imports
    import { inject, computed, defineProps } from 'vue'
    import { useStore } from 'vuex'

    // Images
    import wiks_logo from 'ASSETS/wiks_logo'
    const eye = require('ASSETS/icons/eye')
    const send_email = require('ASSETS/icons/order_card/send_email')
    const download = require('ASSETS/icons/order_card/download')
    const printer = require('ASSETS/icons/order_card/printer')

    // Hooks
    const store = useStore()

    // Injects
    const $informativeDate = inject('$informativeDate')
    const $redirect = inject('$redirect')
        
    // Computeds

    // Props
    const props = defineProps({
        // id_redeem: {
        //     type: Number,
        //     required: false
        // },
        image: {
            type: String,
            required: false,
            default: null
        },
        reference: {
            type: String,
            required: false,
            default: '121230AKDSJDNA'
        },
        status: {
            type: String,
            required: false,
            default: 'Requested'
        },
        creation: {
            type: String,
            required: false,
            default: '2024/11/02'
        },
        points: {
            type: [String, Number],
            required: false,
            default: '80.00'
        },
    });

    // Methods

</script>

<style lang="scss">

    .w-sm-auto {
        width: 100% !important;
    }
    @media (min-width: 576px) {
        .h-sm-100 {
            height: 100% !important;
        }
        .w-sm-auto {
            width: auto !important;
        }
    }

    
</style>