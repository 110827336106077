<template>
    <ws-alert />
    <ws-loading></ws-loading>
    <component :is="layout ? layout : 'section'">
        <router-view/>
    </component>
</template>

<script setup>
	import { computed, onMounted } from 'vue'
	import { useRouter } from 'vue-router'

	//hooks
	const router = useRouter();
	const layout = computed(() => (router.currentRoute.value.meta.layout))
	
</script> 

<style lang="scss">
    *{
        scrollbar-color: #1c3f94 #ececec;
    	scrollbar-width: thin;
    }
    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
    }

    nav {
      padding: 30px;

      a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
          color: #42b983;
        }
      }
    }
</style>
