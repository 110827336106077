<template>
    <div class="loading" v-show="loading.show">
        <div class="loading__container flex-center">
            <div class="row">
                <div class="col-12 flex-center">
                    <div style="max-width: 200px;">
                        <img class="custom_anim img-fluid" :src="logo" />    
                    </div>
                </div>
                <div class="col-12 p-0">
                    <h3 class="dmsans-bold text-white">
                        {{ loading.title }}
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useStore } from "vuex"

    // Images
    const logo = require('ASSETS/wiks_logo')

    // Hooks
    const store = useStore()

    // Injects

    // Computeds
    const loading = computed(() => {
        return store.getters.getLoading
    })

    // Variables


    // Methods

</script>

<style lang="scss" scoped>
    .loading {
        position: fixed;
        height: 100vh;
        width: 100vw;

        justify-content: center;
        align-items: center;
        background-color: #000000bb;
        z-index: 9999;

        &__container {
            width: 100vw;
            height: 100vh;
            z-index: 10000;
            margin: 0;

            text-align: center;
        }

        // Logo animation
        .custom_anim {
            animation-name: pulsar;
            animation-duration: 7s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        @keyframes pulsar {
            0%{
                transform: rotate(1deg) scale(1);
                opacity: 1;
            }

            50%{
                transform: rotate(180deg) scale(1.5);
                opacity: 0;
            }

            100% {
                transform: rotate(359deg) scale(1);
                opacity: 1;
            }
        }

    }
</style>