import { createRouter, createWebHistory } from 'vue-router'
import Home from 'ROUTER/modules/home.js'
import Categories  from 'ROUTER/modules/categories'
import ShoppingCart from 'ROUTER/modules/shoppingCart'
import ViewDetailsOrder from 'ROUTER/modules/viewDetailsOrder'
import viewDetailsRedeem from 'ROUTER/modules/viewDetailsRedeem'
import Offers from 'ROUTER/modules/offers'
import Products from 'ROUTER/modules/products'
import Points from 'ROUTER/modules/points'
import PostHome from 'ROUTER/modules/mainHome'
import Download from 'ROUTER/modules/Download'
import Login from 'ROUTER/modules/login'
import Privacy from 'ROUTER/modules/privacy'
import Terms from 'ROUTER/modules/terms'
import Store from 'STORE/'

const routes = [
  ...Home,
  ...Categories,
  ...ShoppingCart,
  ...ViewDetailsOrder,
  ...viewDetailsRedeem,
  //...Offers,
  ...Products,
  ...Points,
  ...Download,
  ...Login,
  ...PostHome,
  ...Privacy,
  ...Terms,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})


router.beforeEach(async (to, from, next) => {
  //console.log(from)
    //console.log(Store.getters.getLogin)
    const login = Store.getters.getLogin
    if(
      login !== false && to.name == "home" 

    ){
      //console.log("hola moasdasd")
      //console.log(from,"este es from")
      next({name: 'post-home' }) 

    }
    else{
      next()
    }

})
export default router
