<template>

    <section class="container-fluid text-start border rounded dmsans-semibold w-100">
        <div class="row">
            <div class="col-12 d-block d-sm-flex flex-between-center bg-light-gray">
                <div class="dmsans-semibold my-2">

                    Orden: <strong class="dmsans-black">{{ reference }}</strong>

                    <span class="badge text-white p-2 ms-2" 
                        :class="`${
                            (status === 'payment_received') ? 'text-bg-success' :
                            (status === 'assortment') ? 'text-bg-success' :
                            (status === 'cancel') ? 'text-bg-danger' : 'text-bg-dark'
                        }`">
                        {{ 
                            status === 'payment_received' ? 'Pago recibido' :
                            status === 'assortment' ? 'Entregado' :
                            status === 'cancel' ? 'Cancelado' : 'Otro' 
                        }}
                    </span>

                </div>

                <div class="w-sm-auto flex-end-center my-2">
                    <button @click="$redirect('view-deatils-order', { id_order, reference })" class="btn btn-light btn-sm rounded-pill border">
                        Ver Detalles
                    </button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center d-sm-block py-2">
                    
                <div class="image-grid bg-light-gray float-sm-start mb-2 mb-sm-0 p-2">
                    <div class="image-box" v-for="(item, index) in 3" :key="index">
                        <img :src="show_images[index]" 
                            alt="product image" 
                            class="bg-white"
                            style="object-fit: scale-down;"
                            v-if="show_images[index]"/>

                        <div class="w-100 h-100 bg-secondary-light" v-else></div>
                    </div>

          
                    <div class="image-box extra-count bg-secondary-light"
                        :class="remaining_images == 0 ? 'bg-secondary-light' : 'bg-white'"
                        :style="remaining_images == 0 ? 'color: white;' : 'color: #555;'">
                        <span>+{{ remaining_images }}</span>
                    </div>
                </div>

                <div class="d-flex flex-column justify-content-between h-sm-100 w-sm-auto px-3 py-2">

                    <div class="text-start dmsans-light m-0 mb-2 m-sm-2">
                        <span class="dmsans-semibold">Fecha:</span> {{ $informativeDate(creation, 'dw') }}<br>
                        <span class="dmsans-semibold">Total de productos:</span> {{ products.length }}<br>
                        <span class="dmsans-semibold">Total:</span> ${{ total }}<br>
                        <span class="dmsans-semibold">Puntos:</span> {{ (points !== null) ? points : 0 }}

                        <span class="badge dmsans-black text-bg-success text-white p-2 ms-2" v-if="status === 'assortment'">
                            Puntos recibidos
                        </span>
                        <span class="badge dmsans-black text-bg-warning text-dark p-2 ms-2" v-if="status === 'payment_received'">
                            Puntos aun no recibidos
                        </span>
                        <span class="badge dmsans-black text-bg-danger text-white p-2 ms-2" v-if="status === 'cancel'">
                            Puntos cancelados
                        </span>

                    </div>

                    <div class="flex-end-center bg-white m-0 m-sm-2 py-1">
                        <div class="d-flex d-sm-block d-md-flex">
                            <!-- <button class="btn btn-light-gray p-0 px-1 mx-1  mt-0 mt-sm-2 mt-md-0" type="button">
                                <img :src="eye" alt="" />
                            </button> -->
                            <!-- <button class="btn btn-primary p-0 px-1 mx-1 buttonshine  mt-0 mt-sm-2 mt-md-0" type="button">
                                Enviar mi ticket
                            </button> <br class="d-none d-md-block d-sm-none"> -->
                            <button class="btn btn-light-gray p-0 px-0 mx-1  mt-0 mt-sm-2 mt-md-0" type="button">
                                <img :src="send_email" alt="" />
                            </button>
                            <button class="btn btn-light-gray p-0 px-0 mx-1  mt-0 mt-sm-2 mt-md-0" type="button">
                                <img :src="download" alt="" />
                            </button>
                            <button class="btn btn-light-gray p-0 px-0 mx-1  mt-0 mt-sm-2 mt-md-0" type="button">
                                <img :src="printer" alt="" />
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
    </section>
  </template>
  
  <script setup>
    // Imports
    import { inject, computed, defineProps } from 'vue';

    // Images
    import wiks_logo from 'ASSETS/wiks_logo'
    const eye = require('ASSETS/icons/eye')
    const send_email = require('ASSETS/icons/order_card/send_email')
    const download = require('ASSETS/icons/order_card/download')
    const printer = require('ASSETS/icons/order_card/printer')

    // Injects
    const $informativeDate = inject('$informativeDate')
    const $redirect = inject('$redirect')
        
    // Computeds
    const show_images = computed(() => {
        let images = new Array()
        for(let i in props.products) {
            let product = props.products[i]

            if(product.picture !== null ) {
                images.push(product.picture)    
            }
            else {
                images.push(wiks_logo)
            }
        }
        return images.slice(0, 3)
    })
    const remaining_images = computed(() => {
        return (props.products.length > 3) ? props.products.length - 3 : 0
    })

    // Props
    const props = defineProps({
        id_order: {
            type: Number,
            required: true
        },
        images: {
            type: Array,
            default: () => [wiks_logo, wiks_logo, wiks_logo, wiks_logo],
        },
        products: {
            type: Array,
            default: () => [],
        },
        max_width: {
            type: String,
            required: false,
            default: '400'
        },
        reference: {
            type: String,
            required: false,
            default: '121230AKDSJDNA'
        },
        status: {
            type: String,
            required: false,
            default: 'payment_received'
        },
        creation: {
            type: String,
            required: false,
            default: '2024/11/02'
        },
        total: {
            type: [String, Number],
            required: false,
            default: '617.25'
        },
        points: {
            type: [String, Number],
            required: false,
            default: '80.00'
        },
    });

  </script>
  
    <style lang="scss">
        .image-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        .image-box {
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 4px solid #ececec;
            overflow: hidden;
            border-radius: 2px;
        }

        .image-box img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .extra-count {
            font-size: 1.2em;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .w-sm-auto {
            width: 100% !important;
        }
        @media (min-width: 576px) {
            .h-sm-100 {
                height: 100% !important;
            }
            .w-sm-auto {
                width: auto !important;
            }
        }

        
    </style>
  