<template >
    <div class="position-relative d-flex flex-center">
        <img :src="imgbg" class="img-fluid m-md-2 m-1 col-12 imgbg rounded-3 shadow">

        <span class="text-white text-start title dmsans-bold position-absolute top-50 start-0 translate-middle-y mx-md-5 mx-4 textmn" 
            v-html="text">
        </span>
        
        <span class="position-absolute bottom-0 start-0 mx-md-5 mx-4 my-3 text-primary text-date vigenciamn" 
            v-html="vigencia">
        </span>

        <img :src="imgmn" 
            class="position-absolute top-50 end-0 translate-middle-y mx-md-5 mx-4 img-fluid imgmn">
    </div>
</template>
<script setup>
    import { ref } from "vue";
    import defaultImage from '@/assets/images/greenpromobg.png';
    const promo_bg = require('ASSETS/images/greenpromobg')
    

    const props = defineProps({
        imgbg: {
            type: String,
            default: '@/assets/images/greenpromobg.png'
        },
        imgmn: {
            type: String,
            default: "@/assets/images/wiks_logo.png"
        },
        text: {
            type: String,
            default: "promo",
            required: true
        },
        vigencia: {
            type: String,
            default: "Vigencia",
            required: false
        },
        evento: {
            type: String,
            default: "evento",
            required: false
        },
        orden: {
            type: Number,
            default: 1,
            required: false
        },
    })
</script>

<style lang="scss" scoped>

    .imgbg{
        max-width: 95%;
    }
    .textmn{
        max-width: 40%;
        max-height: 90%; 
        font-weight: 900; 
        font-size: clamp(2.1rem, 2vw, 2.1rem); 
        line-height: 1.1;
        text-shadow: 
        -1px -1px 0 #4d9b00,
        1px -1px 0 #4d9b00,
        -1px  1px 0 #4d9b00,
        1px  1px 0 #4d9b00,
        1px 0px 5px #80ff00,
        1px 0px 10px #80ff00,
        1px 0px 15px #80ff00,
        1px 0px 20px #80ff00,
        1px 0px 30px #80ff00;
    }
    @media (max-width: 450px){
        .textmn{
            font-size: clamp(2.6rem, 2vw, 2.6rem);
        }
    }
    .imgmn{
        max-width: 30%; 
        max-height:90%;
    }
    .vigenciamn{
        font-size: clamp(0.5rem, 1vw, 0.5rem);
        max-width: 40%;
    }
</style>