<template>
        
    <!-- [Header] ------------------------------------------------>
    <header class="row p-0 m-0 justify-content-between position-sticky w-100" 
        :class="{ 'bg-primary shadow_nav': has_session }" 
        style="height: 85px; z-index: 100; top: 0;">

        <!-- [Session Menu] ------------------------------------------------>
        <div class="col-xxl-2 col-1 py-2 d-flex justify-content-center position-relative">
            
            <div style="max-width: 90px;">
                <img :src="wiks_logo" 
                    @click.stop="router.push({ name: has_session ? 'post-home' : 'home' })"
                    class="d-none d-lg-inline-block cursor-pointer img-fluid"
                    alt="logo wiks" loading="lazy">
            </div>
            

            <button @click.stop="show_burger_menu = !show_burger_menu" v-if="has_session"
                class="btn d-lg-none"
                type="button">
                <img :src="burger" alt="">
            </button>

            <div class="position-absolute bg-primary dmsans-semibold" v-if="show_burger_menu"
                style="top: 100%; left: 0; width: 200px;">
                <ul class="list-unstyled" @mouseleave="show_burger_menu = false">

                    <template v-for="(option, index) in submenu" :key="`option-${index}`">
                        <li @click.stop="changeCategory(option)" class="py-3 text_hover hav_text" style="font-size: 18px;" v-if="!option.submenu">
                            <img :src="option.icon" alt="" style="max-width: 50px">
                            {{ option.name}}
                        </li>
                    </template>
                </ul>
            </div>
        </div>

        <!-- [Header Center Session] ------------------------------------------------>
        <div class="col-7 col-xxl-6 d-none d-lg-flex flex-column justify-content-between dmsans-semibold h-100 py-2" v-if="has_session">
            <ws-input
                _id="s_i"
                type="text"
                placeholder="Buscar.."
                class="search_input_h w-100 mb-2">
            </ws-input>

            <ul class="list-unstyled flex-between-center w-100 pt-lg-1 pt-xl-0">
                <template v-for="(option, index) in submenu" :key="`optindex-${index}`">

                    <div @click.stop="changeCategory(option)"
                        class="text_hover flex-center hav_text">
                            
                            <div style="max-width: 24px;" class="me-1">
                                <img :src="option.icon" alt="" class="img-fluid">
                            </div>
                            
                            {{ option.name }}

                    </div>
                </template>
            </ul>
        </div>

        <!-- [Header End Session] ------------------------------------------------>
        <div class="col-10 col-md-9 col-lg-4 px-0 mx-0 d-flex justify-content-center">

            <div class="row p-0 m-0 align-items-center justify-content-evenly w-100">

                <div class="col-md-6 col-sm-4 col-2 p-0 m-0 d-flex justify-content-center text-white">
                    <img :src="wp_icon" alt="" class="me-1 icon_hover" v-if="has_session">
                    <div class="dmsans-bold d-none d-sm-block" style="font-size: 1rem;" v-if="has_session">
                        <a class="text-white text-decoration-none" style="cursor: pointer;" href="tel:+525536776619">55-3677-6619 </a>
                        <br>
                        <div class="dmsans-regular" style="font-size:0.5rem;">
                            Atención a clientes por whatsapp
                        </div>
                    </div>
                    
                </div>
                
                <div class="col-md-6 col-sm-8 col-10 p-0 m-0 flex-end-center position-relative h-100 w-100" v-if="!has_session">
                    <button @click.stop="$redirect('Login')"
                        class="btn btn-danger btn-pill img_hover text-white ms-2 px-md-5 px-sm-4"
                        type="button"
                        style="">
                        Registrate
                    </button>
                    <div class="bg-primary mx-2 h-75" style="width: 2px;"></div>
                    <button @click.stop="show_login = !show_login"
                        class="btn btn-primary btn-pill img_hover me-2 px-md-5 px-sm-4"
                        type="button"
                        style="">
                        Inicia Sesión
                    </button>
                    
                    <div class="row position-absolute bg-white border border-1 border-sucess" v-if="show_login"
                        style="top: 100%; right: 5%; width: 260px;">

                        <div class="col-12 p-0 m-0" v-if="!is_logging_process">
                            <div class="row p-0 m-0">

                                <div class="text-start col-7">
                                    <span class="text-dark dmsans-semibold" style="font-size: 20px;">
                                        Inicia Sesión
                                    </span>
                                </div>
                                <div class="col-4 d-flex align-items-end p-0 m-0 pb-1">
                                    <span class="text-start dmsans-regular" style="font-size:11px;">
                                        Crea una cuenta
                                    </span>
                                </div>

                                <div class="col-12 py-2">
                                    <ws-input v-model="state.username"
                                        _id="user_i"
                                        class="default_input"
                                        label="Nombre de usuario o email"
                                        placeholder="Usuario"
                                        type="text">
                                    </ws-input>
                                </div>

                                <div class="col-12 py-2">
                                    <ws-input v-model="state.password"
                                        _id="pwd_i"
                                        class="default_input"
                                        placeholder="password"
                                        label="Password"
                                        type="password">
                                    </ws-input>
                                </div>

                                <div class="col-12 py-2">
                                    <button @click.stop="login"
                                        class="btn btn-primary w-100 img_hover">
                                        Accede
                                    </button>
                                </div>

                                <div class="col-12 py-2 text-danger img_hover">
                                    <span>
                                        ¿Olvidaste tu Constranseña?
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div class="col-12 p-0 m-0 py-3" v-else>
                            <div class="row p-0 m-0">
                                <div class="col-4">
                                    <img style="width: 80px; height: 80px; object-fit: scale-down;" :src="wiks_logo" />
                                </div>

                                <div class="col-8">
                                    <p>
                                        espere un momento...
                                    </p>
                                </div>

                                <div class="col-12">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>

                <div class="col-6 p-0 m-0 d-flex align-items-center justify-content-evenly h-100 position-relative" v-if="has_session">
  
                   <div class="mx-1" style="width: 2px; height: 40px; background-color: #ffffff;"></div>
                   <button class="btn p-0 icon_hover"
                        type="button">
                        <img :src="bell" alt="" style="max-height: 50px">
                   </button>
                   <div class="mx-1" style="width: 2px; height: 40px; background-color: white;"></div>

                   <button @click.stop="cart_modal = true" type="button"
                        class="btn position-relative icon_hover text-white p-0">

                        <img :src="cart" alt="" style="max-height: 35px">

                        <span class="position-absolute bg-danger dmsans-bold border border-1" 
                            style="top: -40%; width: 25px; height: 25px; border-radius: 50%;" 
                            :style="`${cart_products.length > 0 && cart_products_points.length > 0 ? 'right: -30%;' : 'right: -40%;'}` "
                            v-if="cart_products.length > 0">
                                {{ cart_products.length }}
                        </span>

                        <span class="position-absolute bg-success dmsans-bold border border-1" 
                            style="top: -40%;  width: 25px; height: 25px; border-radius: 50%;" 
                            :style="`${cart_products.length > 0 && cart_products_points.length > 0 ? 'right: -60%;' : 'right: -40%;'}` "
                            v-if="cart_products_points.length > 0">
                                {{ cart_products_points.length }}
                        </span>

                   </button>

                   <div class="mx-1" style="width: 2px; height: 35px; background-color: white;"></div>
                   <button  @click.stop="show_logout = !show_logout"
                        class="btn p-0 position-relative me-1 icon_hover"
                        type="button">
                        <img width="40"
                            :src="avatar" alt=""> 
                        <span class="position-absolute text-white" style="right: 0; top: 8px; left: 0; bottom: 0;" v-if="session !== null"> 
                            {{ session.prefix }}
                        </span>
                   </button>


                   <!-- [Menu Desplegable] ------------------------------------------------>

                   <div class="d-flex bg-light-gray position-absolute flex-column text-start"
                        @mouseleave="show_logout = false"
                        style="top: 100%; right: 0px; width: 300px; border-radius: 0px 0px 0px 0px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                        v-if="show_logout">

                        <section class="w-100 bg-light p-3" 
                            :style="{'background-image': `url(${bgorange})`, backgroundSize: 'cover' }">

                            <!-- Image Avatar -->
                            <div class="flex-between-start">
                                <div class="flex-start-center">   
                                    <div class="flex-center bg-primary rounded-circle me-2" style="height: 44px; width: 44px;">
                                        <span class="dmsans-bold text-white"> 
                                            {{ session.prefix }}
                                        </span>
                                    </div>
                                    <div class="dmsans-bold text-white">
                                        {{ (profile.Puntos !== null) ? profile.Puntos : 0 }}Pts.
                                    </div>
                                </div>

                                <div @click="modal_level = true" class="flex-center badge bg-dark cursor-pointer mt-1">
                                    <img :src="
                                        session.level_name === 'Platinum' ? medal_platinum : 
                                        session.level_name === 'Oro' ? medal_gold : 
                                        session.level_name === 'Plata' ? medal_silver : 
                                        session.level_name === 'Bronce' ? medal_copper : medal_white
                                    " 
                                        width="18" height="18" 
                                        class="me-1" />

                                    <span>
                                        {{ (session.level_name !== null && session.level_name !== '') ? session.level_name : 'No afiliado' }}
                                    </span>
                                </div>

                                <!-- <div>
                                    <div class="flex-center badge bg-dark mt-1">
                                        <img :src="medal_platinum" width="18" height="18" class="me-1"> 
                                        <span style="color: white !important;">Platinum</span>
                                    </div>
                                </div> -->

                            </div>
                            
                            

                            <!-- Name Session -->
                            <div class="mt-3 mb-0">
                                <span class="dmsans-black text-white">
                                    {{ session.firstName }} {{ session.lastName }}
                                </span> <br>
                                <span class="dmsans-regular text-white">
                                    {{ session.email }}
                                </span>
                            </div>
                        </section>

                        <section class="py-3">
                                
                            <ul class="dmsans-bold text-start py-0 px-2" 
                                style="list-style-type: none; font-size: 0.9rem;">

                                <li class="m-1 m-sm-2">
                                    <a class="text-decoration-none cursor-pointer text_orange" 
                                        @click.stop="redirectDropMenu(1)">

                                        <img :src="icon_user" width="28" height="28" 
                                            class="mx-sm-2 mx-1"> Perfíl
                                    </a>
                                </li>
                                <li class="m-1 m-sm-2">
                                    <a class="text-decoration-none cursor-pointer text_orange" 
                                        @click.stop="redirectDropMenu(2)">

                                        <img :src="icon_bookmark" width="28" height="28" 
                                            class="mx-sm-2 mx-1"> Favoritos
                                    </a>
                                </li>
                                <li class="m-1 m-sm-2">
                                    <a class="text-decoration-none cursor-pointer text_orange" 
                                        @click.stop="redirectDropMenu(3)">

                                        <img :src="icon_check_list" width="28" height="28" 
                                            class="mx-sm-2 mx-1"> Pedidos
                                    </a>
                                </li>
                                <li class="m-1 m-sm-2">
                                    <a class="text-decoration-none cursor-pointer text_orange" 
                                        @click.stop="redirectDropMenu(4)">

                                        <img :src="icon_exchange" width="28" height="28" 
                                            class="mx-sm-2 mx-1"> Canjes
                                    </a>
                                </li>
                                <li class="m-1 m-sm-2">
                                    <a class="text-decoration-none cursor-pointer text_orange" 
                                        href="https://api.whatsapp.com/send?phone=5215536776619&text=Me%20gustaría%20más%20información" 
                                        target="_blank">

                                        <img :src="icon_bubble_chat_user" width="28" height="28" 
                                            class="mx-sm-2 mx-1"> Atención a Clientes
                                    </a>
                                </li>
                            </ul>
                        </section>

                        <section class="flex-center mb-3">
                            <button @click.stop="logout"
                                class="btn btn-danger btn-pill btn-sm w-75 text-white dmsans-bold"
                                type="button">

                                    Cerrar Sesión
                            </button>
                        </section>

                   </div>
                </div>

            </div>
        </div>
    </header>
    


    <!-- [Shoping Cart Modal] ------------------------------------------------>
    <div class="modal_container_header flex-end-center" v-if="cart_modal">

        <div class="modal_content h-100 border" style="width: 450px; overflow-y: scroll;">

            <!-- Cart Money -->
            <div class="row flex-center-center pt-2 pb-4 px-4 m-0" v-if="cart_products_points.length <= 0">

                <!-- Title -->
                <div class="col-12 flex-between-center dmsans-semibold p-0 py-2">
                    <p class="m-0" style="font-size: 20px;">
                        Lista de compra
                        <span>({{ cart_products.length }})</span>
                    </p>

                    <button @click.stop="cart_modal = false;" type="button"
                        class="btn btn-sm">
                            <span class="close d-block" style="font-size: 30px;">&times;</span>
                    </button>
                </div>

                <!-- Cart -->
                <div class="col-12 border border-1 p-0" 
                    style="max-height: 500px; height: 100% !important; overflow-y: scroll;" v-if="cart_products.length > 0">

                    <div class="row flex-between-center border-1 border-primary border-top border-bottom px-2 p-m-3" 
                        v-for="(product, index) in cart_products" :key="`product-cart-index-${index}`">

                        
                        <div class="col-12 flex-center py-2">
                            <div class="flex-column flex-center me-4" role="group" 
                                aria-label="button for shopping car">

                                <button @click.stop="() => {product.qty--; updateCartProduct(product)}" type="button" 
                                    :disabled="product.qty === 1 || updating_cart"
                                    class="btn btn-sm btn-primary btn-group-top p-0">
                                        <img :src="less" alt="" width="18" height="18" v-if="!updating_cart">
                                        <div class="spinner-border spinner-border-sm text-white" 
                                            style="margin: 0px 1px" 
                                            role="status" 
                                            v-if="updating_cart">
                                                <span class="visually-hidden">Loading...</span>
                                        </div>
                                </button>

                                <button type="button" class="btn btn-sm btn-primary rounded-0 p-0 w-100">
                                    <b class="m-0">{{ product.qty }}</b>
                                </button> 

                                <button @click.stop="() => {product.qty++; updateCartProduct(product)}" type="button" 
                                    :disabled="updating_cart"
                                    class="btn btn-sm btn-primary btn-group-bottom p-0">
                                        <img :src="more" alt="" width="18" height="18" v-if="!updating_cart">
                                        <div class="spinner-border spinner-border-sm text-white" 
                                            style="margin: 0px 1px" 
                                            role="status" 
                                            v-if="updating_cart">
                                                <span class="visually-hidden">Loading...</span>
                                        </div>
                                </button>
                            </div>

                            <div class="border" style="max-width: 70px;">
                                <img :src="`https://api.wiks-services.online/administrator/products/resources?id=${product.code}`" alt="" 
                                    class="img-fluid">
                            </div>
                            
                            <div class="text-start dmsans-regular w-75 px-3" style="font-size: 14px">
                                <span>
                                    {{ product.name }}
                                </span> <br>
                                <div class="w-100 flex-between-center">
                                    <span class="dmsans-bold">
                                        <span style="font-size: 12px; color: #AAA">1pza </span>
                                        <span>${{ formatAmount(Number(product.unity)) }}</span>
                                    </span> 
                                    <span class="dmsans-bold">
                                        <span style="font-size: 12px; color: #AAA">total </span>
                                        <span>${{ formatAmount(Number(product.price)) }}</span>
                                    </span>    
                                </div>
                            </div>

                            <div class="rounded-circle flex-center border" style="width: 24px; height: 24px">
                                <button @click.stop="removeFromCart(product)" class="btn">
                                    <span class="text-danger" 
                                        style="font-size: 24px; margin-top: -20px;" 
                                        v-if="!updating_cart">
                                            &times;
                                    </span>

                                    <div class="spinner-border spinner-border-sm text-dark" 
                                        style="margin: 0px 1px" 
                                        role="status" 
                                        v-if="updating_cart">
                                            <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Button redirect -->
                <div class="col-12 dmsans-semibold py-3" style="max-height: 500px; height: 100%; " v-else>
                    <p>No has añadido productos a tu carrito.</p>
                    <button @click.stop="store.commit('setCurrentTab', 3); router.push({ path: '/shopping-cart'})" 
                        class="btn btn-sm btn-danger text-white">
                            Ir a mis pedidos
                    </button>
                </div>

                <!-- Summary -->
                <div class="col-12 border border-1 text-start m-0 mt-3 py-2">

                    <div class="w-100 pb-3">
                        <p class="dmsans-semibold m-0" style="font-size: 20px;">
                            Resumen del pedido
                        </p>
                    </div>
                    

                    <div class="flex-between-center w-100 pb-1">
                        <p class="m-0">
                            Total de Compra:
                        </p>
                        <p class="m-0">
                            MXN {{ formatAmount(subtotal) }}
                        </p>
                    </div>

                    <div class="flex-between-center w-100 pb-1">
                        <p class="m-0">
                            Descuentos:
                        </p>
                        <p class="m-0">
                            MXN {{ formatAmount(discounts) }}
                        </p>
                    </div>

                    <div class="flex-between-center w-100">
                        <p class="m-0">
                            Puntos ganados con esta compra:
                        </p>
                        <p class="m-0">
                            Pts {{ formatAmount(0) }}
                        </p>
                    </div>

                    <hr class="border border-2 my-1">

                    <div class="flex-between-center w-100 dmsans-bold">
                        <p class="m-0">
                            TOTAL DE VENTA 
                        </p>
                        <p class="text-success m-0" style="font-size: 18px;">
                            MXN {{ formatAmount(total) }}
                        </p>
                    </div>
                </div>

                <!-- Buttons -->
                <div class="col-12 border border-1 text-start m-0 mt-3 py-2">
                    <p class="dmsans-bold m-0 pb-2" style="font-size: 20px;">
                        Método de pago
                    </p>

                    <div class="form-check">
                        <input v-model="payment_method" id="radio-cash" value="cash" class="form-check-input" type="radio">
                        <label class="form-check-label" for="radio-cash">
                            En contra entrega
                        </label>
                    </div>
                    <div class="form-check">
                        <input v-model="payment_method" id="radio-card" value="card" class="form-check-input" type="radio">
                        <label class="form-check-label" for="radio-card">
                            Con tarjeta de crédito o debito
                        </label>
                    </div>

                    <button @click.stop="selectButtonAction()"
                        :disabled="cart_products.length == 0"
                        class="btn btn-danger text-white dmsans-semibold w-100 mt-3"
                        type="button">
                        Finalizar compra
                    </button>
                </div>

            </div>


            <!-- Cart Points -->
            <div class="row flex-center-center pt-2 pb-4 px-4 m-0" v-if="cart_products_points.length > 0">

                <!-- Title -->
                <div class="col-12 flex-between-center dmsans-semibold p-0 py-2">
                    <p class="m-0" style="font-size: 20px;">
                        Lista de compra
                        <span>({{ cart_products_points .length }})</span>
                    </p>

                    <button @click.stop="cart_modal = false;" 
                        type="button"
                        class="btn btn-sm">
                            <span class="close d-block" style="font-size: 30px;">&times;</span>
                    </button>
                </div>

                <!-- Cart -->
                <div class="col-12 border border-1 p-0" 
                    style="max-height: 500px; height: 100% !important; overflow-y: scroll;">

                    <div class="row flex-between-center border-1 border-primary border-top border-bottom px-2 p-m-3" 
                        v-for="(product, index) in cart_products_points" :key="`product-cart-index-${index}`">
                        
                        <div class="col-12 flex-center py-2">
                            <div class="flex-column flex-center me-4" role="group" 
                                aria-label="button for shopping car">


                                <button @click.stop="decreaseQuantity(product)" type="button" 
                                    :disabled="product.qty === 1 || updating_cart"
                                    class="btn btn-sm btn-primary btn-group-top p-0">
                                        <img :src="less" alt="" width="18" height="18" v-if="!updating_cart">
                                        <div class="spinner-border spinner-border-sm text-white" 
                                            style="margin: 0px 1px" 
                                            role="status" 
                                            v-if="updating_cart">
                                                <span class="visually-hidden">Loading...</span>
                                        </div>
                                </button>

                                <button type="button" class="btn btn-sm btn-primary rounded-0 p-0 w-100">
                                    <b class="m-0">{{ product.qty }}</b>
                                </button> 

                                <button @click.stop="increaseQuantity(product)" type="button" 
                                    :disabled="updating_cart"
                                    class="btn btn-sm btn-primary btn-group-bottom p-0">
                                        <img :src="more" alt="" width="18" height="18" v-if="!updating_cart">
                                        <div class="spinner-border spinner-border-sm text-white" 
                                            style="margin: 0px 1px" 
                                            role="status" 
                                            v-if="updating_cart">
                                                <span class="visually-hidden">Loading...</span>
                                        </div>
                                </button>
                            </div>

                            <div class="border" style="max-width: 70px;">
                                <img :src="product.image" alt="" 
                                    class="img-fluid">
                            </div>
                            
                            <div class="text-start dmsans-regular w-75 px-3" style="font-size: 14px">
                                <span>
                                    {{ product.title }}
                                </span> <br>
                                <div class="w-100 flex-between-center">
                                    <span class="dmsans-bold">
                                        <span style="font-size: 12px; color: #AAA">1pza </span>
                                        <span>Pts. {{ formatAmount(Number(product.points)) }}</span>
                                    </span> 
                                    <span class="dmsans-bold">
                                        <span style="font-size: 12px; color: #AAA">total </span>
                                        <span>Pts.  {{ formatAmount(Number(product.points) * Number(product.qty)) }}</span>
                                  x  </span>    
                                </div>
                            </div>

                            <div class="rounded-circle flex-center border" style="width: 24px; height: 24px">
                                <button @click.stop="removeFromPointsCart(product)" class="btn">
                                    <span class="text-danger" 
                                        style="font-size: 24px; margin-top: -20px;" 
                                        v-if="!updating_cart">
                                            &times;
                                    </span>

                                    <div class="spinner-border spinner-border-sm text-dark" 
                                        style="margin: 0px 1px" 
                                        role="status" 
                                        v-if="updating_cart">
                                            <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Button redirect -->
                <!-- <div class="col-12 dmsans-semibold py-3" style="max-height: 500px; height: 100%; " v-else>
                    <p>No has añadido productos a tu carrito.</p>
                    <button @click.stop="store.commit('setCurrentTab', 4); router.push({ path: '/shopping-cart'})" 
                        class="btn btn-sm btn-danger text-white">
                            Ir a mis canjes
                    </button>
                </div> -->

                <!-- Summary -->
                <div class="col-12 border border-1 text-start m-0 mt-3 py-2">
                    <div class="w-100 pb-3">
                        <p class="dmsans-semibold m-0" style="font-size: 20px;">
                            Resumen del pedido
                        </p>
                    </div>

                    <hr class="border border-2 my-1">

                    <div class="flex-between-center w-100 dmsans-bold">
                        <p class="m-0">
                            Total de Compra:
                        </p>
                        <p class="text-success m-0" style="font-size: 18px;">
                            Pts. {{ formatAmount(total_points) }}
                        </p>
                    </div>
                </div>
                    

                <!-- Buttons -->
                <div class="col-12 border border-1 text-start m-0 mt-3 py-2">

                    <button @click.stop="processCheckout()"
                        :disabled="cart_products_points.length == 0"
                        class="btn btn-danger text-white dmsans-semibold w-100 mt-3"
                        type="button">
                        Finalizar canje
                    </button>
                </div>

                <div class="text-start dmsans-semibold">
                    <small style="font-size: 12px;">
                        *Para realizar una compra del carrito, primero finaliza tu compra con puntos
                    </small>    
                </div>
            </div>

        </div>

    </div>

    <!-- [Payment Card Modal] ------------------------------------------------>
    <ws-modal centered max_width="780" v-if="payment_modal">

        <div class="w-100 pb-4 position-relative">

            <!-- head -->
            <div class="w-100 flex-between-center bg-danger py-2">
                <div class="flex-start-center" style="width: 100px;">
                    <img :src="wiks_logo" 
                        alt="" 
                        width="50" 
                        class="ps-3" 
                        style="object-fit: scale-down;">    
                </div>

                <div class="flex-center">
                    <h4 class="dmsans-semibold text-white m-0">
                        Formulario de Pago
                    </h4>
                </div>

                <img :src="openpay_logo" 
                    alt=""
                    width="100" 
                    class="pe-3">
            </div>

            <form class="row text-start dmsans-regular p-3" ref="payment_form" id="processCard" name="processCard">
                <div class="col-12 mb-2">
                    <p class="m-0">
                        Nombre en la tarjeta:
                    </p>
                    <input class="simple_input" data-openpay-card="holder_name" type="text">
                </div>
                
                <div class="col-12 mb-2">
                    <p class="m-0">
                        Número de tarjeta:
                    </p>
                    <input maxlength="16" class="simple_input" data-openpay-card="card_number" type="text">
                </div>

                <div class="col-4 mb-2">
                    <p class="m-0">
                        Mes:
                    </p>
                    <input maxlength="2" class="simple_input" data-openpay-card="expiration_month" type="text">
                </div>
                
                <div class="col-4 mb-2">
                    <p class="m-0">
                        Año:
                    </p>
                    <input maxlength="2" class="simple_input" data-openpay-card="expiration_year" type="text">
                </div>

                <div class="col-4 mb-2">
                    <p class="m-0">
                        CVV:
                    </p>
                    <input maxlength="3" class="simple_input" data-openpay-card="cvv2" type="text">
                </div>
            </form>
                
            <!-- Images -->
            <div class="w-100 mb-2">
                <img :src="banks_1" alt="" width="170">
                <img :src="banks_2" alt="" width="270">
            </div>

            <!-- Buttons -->
            <div class="w-100 flex-center pt-2">
                <div v-show="!processing_order">
                    <button @click.stop="payment_modal = false"
                        type="button"
                        class="btn btn-danger btn-sm btn-pill text-white dmsans-bold me-4">
                            Cancelar 
                    </button>

                    <button @click.stop="createToken"
                        type="button"
                        class="btn btn-primary btn-sm btn-pill dmsans-bold">
                            Procesar pago 
                    </button>
                </div>
                <div class="flex-center" v-show="processing_order">
                    <div class="spinner-border text-primary me-2" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Procesando pago, espere un momento
                </div>
            </div>
        </div>
    </ws-modal>

    <!-- [Modal Redeem message] ------------------------------------------------>
    <ws-modal centered max_width="560" v-if="modal_points !== null">

        <div class="modal-content position-relative bg-light-gray px-3 py-3" 
            v-if="modal_points == 'success'">

            <div @click.stop="modal_points = null"
                class="position-absolute cursor-pointer" 
                style="top: -16px; right: 8px; font-size: 48px">
                    <span class="dmsans-bold text-white">&times</span>
            </div>

            <div class="montserrat-bold text-start m-2" 
                style="font-size: clamp(1.2rem, 3vw, 1.5rem); font-weight: 700;">

                <div class="w-100 text-center">
                    <div class="flex-center w-100 me-2 mt-2 mb-3">
                        <div class="flex-center rounded-circle bg-white px-3 py-4" style="max-width: 140px;">   
                            <img :src="wiks_logo" class="img-fluid">
                        </div>
                    </div>
                        
                    <div class="dmsans-bold text-primary px-2">
                        <h2>Canjee exitoso</h2>
                    </div>

                    <div class="text-black px-2">
                        <h5>Has canjeado tus puntos exitosamente</h5>
                    </div>
                </div>

                <div class="w-100 flex-center pt-2">
                    <button @click.stop="goToPoints" type="button" 
                        class="btn btn-primary dmsans-bold px-5">
                        Ver Canjes
                    </button>    
                </div>
                
            </div>
        </div>

        <div class="modal-content position-relative bg-light-gray px-3 py-3" 
            v-if="modal_points == 'decline'">

            <div @click.stop="modal_points = null"
                class="position-absolute cursor-pointer" 
                style="top: -12px; right: 8px; font-size: 36px">
                    <span class="dmsans-bold text-white">&times</span>
            </div>

            <div class="montserrat-bold text-start m-2" 
                style="font-size: clamp(1.2rem, 3vw, 1.5rem); font-weight: 700;">

                <div class="w-100 text-center">
                    <div class="flex-center w-100 me-2 mt-2 mb-3">
                        <div class="flex-center rounded-circle bg-white px-3 py-4" style="max-width: 140px;">   
                            <img :src="wiks_logo" class="img-fluid">
                        </div>
                    </div>
                        
                    <div class="dmsans-bold text-primary px-2">
                        <h2>Ha ocurrido un error</h2>
                    </div>

                    <div class="text-black px-2">
                        <h5>No se pudo realizar tu canje, vuelve a intentarlo <br> mas tarde</h5>
                    </div>
                </div>
            </div>
        </div>
    </ws-modal>

    <!-- [Modal Redeem message] ------------------------------------------------>
    <ws-modal centered max_width="560" v-if="modal_level === true">

        <div class="modal-content position-relative bg-light-gray px-3 pt-5 pb-3">

            <div @click.stop="modal_level = false"
                class="position-absolute cursor-pointer" 
                style="top: -16px; right: 8px; font-size: 48px">
                    <span class="dmsans-bold">&times</span>
            </div>

            <section v-if="session.level !== null && session.level !== 0">

                <h3>Hola! <span class="text-danger">{{ session.RazonSocial }}</span> Tu nivel es: </h3>

                <div @click="modal_level = true" 
                    class="badge bg-dark cursor-pointer mt-1">

                    <div class="flex-center">

                        <img :src="
                            session.level_name === 'Platinum' ? medal_platinum : 
                            session.level_name === 'Oro' ? medal_gold : 
                            session.level_name === 'Plata' ? medal_silver : 
                            session.level_name === 'Bronce' ? medal_copper : medal_white
                        " 
                            width="32" height="32" 
                            class="me-1" />

                        <span class="dmsans-bold" style="font-size: 18px">
                            {{ (session.level_name !== null && session.level_name !== '') ? session.level_name : 'No afiliado' }}
                        </span>

                    </div>
                </div>

                <div class="border border-4 rounded mt-3" style="border-color: #FD7E14 !important">
                    <p class="dmsans-semibold mb-2">Tus beneficio es:</p>

                    <h1 class="dmsans-bold mb-2">4%</h1> 
                    <p class="dmsans-semibold m-0">de obtención de puntos</p>

                </div>
            </section>

            <section class="w-100 text-center" v-else>
                <div class="flex-center w-100 me-2 mt-2 mb-3">
                    <div class="flex-center rounded-circle bg-white px-3 py-4" style="max-width: 140px;">   
                        <img :src="wiks_logo" class="img-fluid">
                    </div>
                </div>
                    
                <div class="dmsans-bold text-primary px-2">
                    <h2>Unete a nuestro programa</h2>
                </div>

                <div class="text-black px-2">
                    <h5>Aun no formas parte del programa de puntos Wiks</h5>
                </div>

                <div class="flex-center text-black px-2">
                    <a class="text-white text-decoration-none cursor-pointer" 
                        href="https://wa.me/+525536776619" target="_blank">
                        <button class="btn btn-danger flex-center dmsans-bold text-white">
                            <img :src="wp_icon" width="24" height="24" class="me-1">
                            Escribenos
                        </button>
                    </a>
                </div> 
            </section>
        </div>

        
    </ws-modal>
</template>


<script setup>
    // Images
    const wiks_logo = require('ASSETS/wiks_logo')
    const burger = require('ASSETS/icons/burger')
    const bell = require('ASSETS/icons/bell_icon')
    const cart = require('ASSETS/icons/shopcart')
    const avatar = require('ASSETS/icons/avatar')
    const trash = require('ASSETS/icons/trash_icon')
    const wp_icon = require('ASSETS/icons/whatsapp')
    const arrow = require('ASSETS/icons/arrow.png')

    const beer = require('ASSETS/icons/beer.png')
    const grocery = require('ASSETS/icons/grocery.png')
    const soda = require('ASSETS/icons/soda.png')
    const start = require('ASSETS/icons/start.png')
    const water = require('ASSETS/icons/water.png')
    const wine = require('ASSETS/icons/wine.png')

    const call = require('ASSETS/icons/call.png')
    const favorite = require('ASSETS/icons/favorite.png')
    const history = require('ASSETS/icons/history.png')
    const person = require('ASSETS/icons/person.svg')

    const less = require('ASSETS/icons/minus_white')
    const more = require('ASSETS/icons/plus_white')

    const openpay_logo = require('ASSETS/icons/openpay_logo')
    const banks_1 = require('ASSETS/images/banks_icon_1')
    const banks_2 = require('ASSETS/images/banks_icon_2')
    const bgorange = require('ASSETS/images/bgorange')
    const beers = require('ASSETS/images/check')
    const sadbeer = require('ASSETS/images/error')

    // Images for dropmenu
    const icon_user = require('ASSETS/icons/dropdown_menu/user')
    const icon_bookmark = require('ASSETS/icons/dropdown_menu/bookmark')
    const icon_check_list = require('ASSETS/icons/dropdown_menu/check_list')
    const icon_exchange = require('ASSETS/icons/dropdown_menu/exchange')
    const icon_bubble_chat_user = require('ASSETS/icons/dropdown_menu/bubble_chat_user')
    const medal_platinum = require('ASSETS/icons/dropdown_menu/medal_platinum')
    const medal_gold = require('ASSETS/icons/dropdown_menu/medal_gold')
    const medal_silver = require('ASSETS/icons/dropdown_menu/medal_silver')
    const medal_copper = require('ASSETS/icons/dropdown_menu/medal_copper')
    const medal_white = require('ASSETS/icons/dropdown_menu/medal_white')


    // Imports Vue
    import { ref, inject, computed, reactive, resolveComponent, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    // Hooks
    const store = useStore();
    const router = useRouter();

    // Injects
    const $redirect = inject('$redirect')

    // Composables
    import { formatAmount } from 'COMPOSABLES/formatAmount';

    // Variables
    const client_id = process.env.VUE_APP_ID;
    const state = reactive({
        username: null,
        password: null
    })
    const payment_method = ref('cash')
    const payment_modal = ref(false)
    const processing_order = ref(false)
    const payment_form = ref(null)
    const modal_points = ref(null)
    const modal_level = ref(false)
    
    const menu = ref([
        {
            label: 'Cerveza',
            route: 'categories',
            img: 'ASSETS/icons/arrow.png',
            
        },
        {
            label: 'Refrescos',
            route: 'categories',
            img: 'ASSETS/icons/arrow.png',
        },
        {
            label: 'Vinos y Licores',
            route: 'categories',
            img: 'ASSETS/icons/arrow.png',
        },
        {
            label: 'Abarrotes',
            route: 'categories',
            img: 'ASSETS/icons/arrow.png',
        },
        {
            label: 'Agua y Hielos',
            route: 'categories',
            img: 'ASSETS/icons/arrow.png',
        },
        {
           label: 'Wiks Puntos',
           open: false,
           submenu : [
               {
                   label: 'Productos',
                   route: 'points-products'
               },
               {
                   label: 'Centro de canje',
                   route: 'points-checkout'
               }
           ]
        }
    ])

    const show_login = ref(false);
    const show_logout = ref(false);
    const show_burger_menu = ref(false);
    const cart_modal = ref(false);
    const is_logging_process = ref(false);

    // Computed
    const updating_cart = computed(() => (store.getters['sales/getUpdatingCart']))
    const reference = computed(() => (store.getters['sales/getReference']));
    //const point_history = computed(() => store.getters['program/getRedeems']);
    //const get_session = computed(() => store.getters['getSession']);
    //const point_detail = computed(() => store.getters['program/getRedeem']);
    const families = computed(() => (store.getters['sales/getFamilies']))
    const submenu = computed(() => {
        const icons = [beer, soda, wine, water, grocery,  start]

        let mn_menu = families.value.map((item, index) => {
            return {
                id: item.id,
                name: item.name,
                route: 'categories',
                icon: icons[index] || defaultIcon 
            };
        });
        
        // Agrega el ícono específico para 'wiks puntos'
        mn_menu = [...mn_menu, {id: 0, name: 'Wiks Puntos', route: 'points-products', icon: start}];

        return mn_menu;
    });

    const has_session = computed(() => (store.getters['getLogin']));
    const session = computed(() => (store.getters['getSession']))
    const profile = computed(() => (store.getters['program/getProfiles']))
    const alert = computed(() => (store.getters['getAlert']))

    const type_cart = computed(() => (store.getters['sales/getTypeCart']));
    const order = computed(() => (store.getters['sales/getOrder']));
    const cart_products = computed(() => (store.getters['sales/getCartProducts']));
    const cart_products_points = computed(() => (store.getters['program/getCartProducts']));

    const token_found = computed(() => (store.getters['sales/getTokenFound']))

    // Computeds For Sales
    const subtotal = computed(() => {
        let products = cart_products.value
        let subtotal = 0

        for(let product in products) {
            let raw_total = formatAmount(Number(products[product].unity) * products[product].qty, 2)
            subtotal = formatAmount(Number(subtotal) + Number(raw_total), 2)
        }
        
        return subtotal
    })
    const discounts = computed(() => {
        let products = cart_products.value
        let discount = 0

        for(let index in products) {
            discount += products[index].discount * products[index].qty;
        }
        
        return discount
    })
    const total = computed(() => {
        
        let total = formatAmount(subtotal.value - discounts.value);
        
        return total
    });
    const iva = computed(() => {
        let products = cart_products.value
        let iva = 0

        for(let product in products) {
            iva = formatAmount(Number(iva) + Number(products[product].iva))
        }
        
        return iva
    })
    // Computeds For Points
    const total_points = computed(() => {
        return store.getters['program/getCartProducts'].reduce((total, product) => {
            return total += product.points * product.qty;
        }, 0);
    });
    

    // Methods
    const login = async () => {
        is_logging_process.value = true

        const body = {
            username: state.username,
            password: state.password
        }

        await store.dispatch('login', body)
        console.log(has_session)

        if(!has_session.value) {
            is_logging_process.value = false
        } 
        else {
            is_logging_process.value = false
            state.username = null
            state.password = null
            show_login.value = false
            router.push({name:"post-home"})
        }
        
    }

    const logout = () => {
        store.commit('setSession', null)
        store.commit('sales/setReference', null)
        store.commit('setLogin', false)
        sessionStorage.clear()
        show_logout.value = false
        router.push({name:"home"})
        
    }

    const changeCategory = async (family) => {
          await store.dispatch('sales/listCategories', {family_id: family.id})
          //console.log(family.name)
          router.push({name: family.route})
          show_burger_menu.value = false
    }


    const removeFromCart = async (product) => {
        await store.commit('sales/setUpdatingCart', true)
        await store.dispatch('sales/removeFromShoppingCart', { code: product.code })
        await store.commit('sales/setUpdatingCart', false)
    }

    const updateCartProduct = async (product) => {
        await store.commit('sales/setUpdatingCart', true)
        await store.dispatch('sales/addToShoppingCart', {code: product.code, qty: product.qty, total: formatAmount(product.unity * product.qty, 2), promo: false, name: product.name})
        await store.commit('sales/setUpdatingCart', false)
    }

    const openSidebar = () => {
        const offcanvasRight = document.getElementById('offcanvasRight')

        if(offcanvasRight.classList.contains('show')) {
            offcanvasRight.classList.remove('show')
        }
        else {
            offcanvasRight.classList.add('show')
        }

        offcanvasRight.addEventListener('hidden.bs.offcanvas', event => {
            // do something...
        })
    }

    const selectButtonAction = () => {
        if(payment_method.value == "cash") {
            goToPayment()
        }
        if(payment_method.value == "card") {
            payment_modal.value = true
        }
    }


    // Methods of Payment

    const goToPayment = async () => {
        store.dispatch('showLoading', null, { root: true })

        let order_data = {
            query: {
                reference: reference.value
            },
            body: {
                payment: 'cash',
            }
        }
        await store.dispatch('sales/generateOrder', order_data)
        await store.dispatch('sales/listOrderHistory')

        setTimeout(() => {
            store.dispatch('hiddenLoading', null, { root: true })
        }, 500)
    }

    const createToken = async () => {
        const form = payment_form.value;

        processing_order.value = true;

        await OpenPay.token.extractFormAndCreate(
            form,

            async function(token_data) {
                let deviceDataId = OpenPay.deviceData.setup("processCard");
                console.log(deviceDataId, 'deviceDataId')
                
                let data = {
                    body: {
                        amount: total.value,
                        is3ds: false,
                        token: token_data.data.id,
                        device_id: deviceDataId
                    },
                    query: {
                        reference: reference.value
                    }
                }
                let a = await store.dispatch('sales/processSale', data)
                console.log(a, 'a')

                if(token_found.value) {
                    let order_data = {
                        query: {
                            reference: reference.value
                        },
                        body: {
                            payment: 'card',
                            card: {
                                holder: 'test',
                                number: 1234
                            }
                        }
                    }

                    await store.dispatch('sales/generateOrder', order_data)
                    form.reset()
                    processing_order.value = false
                    return
                }
                
                processing_order.value = false
                
            },
            function (error) {
                console.log('error =>', error)
                store.commit('setAlert', {
                    open: true,
                    message: 'Ha ocurrido un error intentando procesar su compra intente nuevamente.',
                    variant: 'danger',
                    text_color: 'white',
                    timeout: 3000
                })

                processing_order.value = false
            }, 
                client_id
            );
        
    }


    // Methods of Points Item

    const increaseQuantity = (product) => {
        let product_index = cart_products_points.value.findIndex(item_product => item_product.id === Number(product.id));

        if(product_index > -1) {
            cart_products_points.value[product_index].qty++;
            store.dispatch('program/updateCart', cart_products_points.value);
        }
    }

    const decreaseQuantity = (product) => {
        store.dispatch('program/removeFromCart', product.id);
    }

    const removeFromPointsCart = (product) => {
        let product_index = cart_products_points.value.findIndex(item_product => item_product.id === Number(product.id));
        delete cart_products_points.value[product_index];

        const products = [...cart_products_points.value.filter((item) => true)];
        store.dispatch('program/updateCart', products);
    }

    const processCheckout = async () => {
        let products = store.getters['program/getCartProducts'].map(product => {
            return {
                id: Number(product.id),
                qty: product.qty
            };
        });

        await store.dispatch('program/pointCheckout', { products: products });
        await store.dispatch('program/listProfiles');

        if(alert.value.message == 'Proceso realizado con éxito.') {
            modal_points.value = 'success';
        } 
        else if (alert.value.message == "Un error ha ocurrido en tu canje, intente más tarde.") {
            modal_points.value = 'decline';
        }
        else if (alert.value.message == "Lo sentimos, parece que no tienes los puntos suficientes.") {
            console.log(alert.value.message);
        }
    }

    const goToPoints =  async () => { 
        store.commit('setCurrentTab', 4);
        router.push({ path: '/shopping-cart'});
        modal_points.value = null
        await store.dispatch('program/listProfiles');
    }

    const redirectDropMenu = (tab = null) => {
        tab = (tab !== null) ? tab : 1

        store.commit('setCurrentTab', tab);
        $redirect('shopping-cart')
    }


    // Vue CicleLife
    onMounted(async() => {
        try {
            console.log(session.value, 'session')

            if(session.value !== null) {
                await store.dispatch('program/listProfiles');
                await store.dispatch('sales/listFamily');
            }
        }
        catch(e) { 
            console.error(e)
        }
    })

    // onMounted(async() => {
    //     try {
    //         if(get_session.value !== null){
    //             await store.dispatch('program/listPointRedeems');
    //             console.log(point_history.value, "poinnnnnnnn")
    //             if(point_history.value !== null && point_history.value.length > 0)  {
    //             //     await store.dispatch('program/viewPointRedeem', { reference: point_history.value[0].Referencia });
    //             }
    //         }
    //         console.log(get_session.value , "este")
    //         loading.value = false
    //     } catch (e) {
    //         console.log(e)
    //     }
    // })
    
</script>


<style lang="scss">
    
    .offcanvas.show {
        visibility: visible;
    }
    .offcanvas.show .navbar-collapse {
        display: flex;
    }
    .offcanvas .navbar-nav .nav-link {
        color: #000;
    }
    .offcanvas .navbar-nav .nav-link:hover {
        color: #d11818;
    }

    .modal_container_header {
        width: 100%;
        height: 100vh;
        background: rgb(91, 91, 91, 0.5);
        top: 0;
        position: fixed;
        z-index: 110;
        display: flex;
        align-items: center;

        .modal_content {
            background: white;
            position: relative;
        }
    }

</style>