import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// import BOOTSTRAP
import 'ASSETS/scss/bootstrap/bootstrap.scss'
// import CUSTOM
import 'ASSETS/scss/custom.scss'

import 'vue3-carousel/dist/carousel.css'

//libs
import _Lodash from 'lodash'
import _redirect from 'LIBS/redirect.js'
import _informativeDate from 'LIBS/informativeDate.js'

//templates
import T_default from 'TEMPLATES/Default.vue'
import T_center from 'TEMPLATES/Center.vue'

//componentes
import Footer from 'COMPONENTS/Footer.vue'
import Header from 'COMPONENTS/Header.vue'
import Input from 'COMPONENTS/Input.vue'
import Slider from 'COMPONENTS/Slider.vue'
import Card from 'COMPONENTS/Card.vue'
import CardHome from 'COMPONENTS/CardHome.vue'
import Modal from 'COMPONENTS/Modal.vue'
import Alert from 'COMPONENTS/Alert.vue'
import Loading from 'COMPONENTS/Loading.vue'
import Loader from 'COMPONENTS/Loader.vue'
import Carousel from 'COMPONENTS/Carousel.vue'
import Promo from '@/components/Promo.vue'
import OrderCard from 'COMPONENTS/OrderCard.vue'
import OrderRedeem from 'COMPONENTS/OrderRedeem.vue'

//OpenPay
OpenPay.setId(process.env.VUE_APP_ID);
OpenPay.setApiKey(process.env.VUE_APP_OPENPAY_KEY);
OpenPay.setSandboxMode(true);

console.log('mode =>',OpenPay.getSandboxMode())
createApp(App)
.component('default', T_default)
.component('center', T_center)
.component('ws-header', Header)
.component('ws-footer', Footer)
.component('ws-input', Input)
.component('ws-slider', Slider)
.component('ws-card', Card)
.component('ws-card-home', CardHome)
.component('ws-modal', Modal)
.component('ws-alert', Alert)
.component('ws-loading', Loading)
.component('ws-loader', Loader)
.component('ws-carousel', Carousel)
.component('ws-promo', Promo)
.component('ws-OrderCard', OrderCard)
.component('ws-OrderRedeem', OrderRedeem)
.provide('$_', _Lodash)
.provide('$redirect', _redirect)
.provide('$informativeDate', _informativeDate)
.use(store)
.use(router)
.mount('#app')
